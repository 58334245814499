.person-info
	display flex
	flex-direction column

	&__text
		margin-bottom 0
		display block
		font-family 'Arial'
		font-size 14px
		text-decoration none
		color #666666

		&_mb
			margin-bottom 12px

		&_name
			font-size 16px
			font-weight 600
			color #000000

		&_link
			color #2175c3

		&_grey
			color #cccccc

	&__photo-wrapper
		margin-bottom 19px
		width 180px
		height 120px
		flex-shrink 0

	&__photo
		width 100%
		height 100%
		object-fit cover

	&_mb
		margin-bottom 75px

	&_row
		flex-direction row
		flex 1

		.person-info__info
			display grid
			grid-template-rows 50px 50px
			grid-template-columns 50% 50%
			grid-column-gap 30px

	&_style-2
		.person-info__photo-wrapper
			margin-right 13px
			margin-bottom 0
			width 100px
			height 100px

		.person-info__photo
			border-radius 50%

	&_style-3
		flex 1

		.person-info__photo-wrapper
			width 100px
			height 100px

		.person-info__photo
			border-radius 50%

	&_center
		text-align center
		align-items center

	&_dark
		.person-info__text_name
			color #ffffff
