import('sprites/*')
import('helpers/svg-size')

@import 'helpers/variables'
@import 'helpers/mixins'
@import 'helpers/fonts'
@import 'helpers/optimize'

import('../blocks/**/*')

body
	position relative
	display flex
	flex-direction column
	line-height 1.2

footer
	display flex
	justify-content space-between

.footer-wrapper
	position absolute
	right 0
	left 0
	bottom 25px

.header-wrapper
	padding-top 20px
	padding-bottom 20px
	background #ffffff

.wrapper
	height 400px
	background #f5f5f5

.template
	padding 70px 0
	background-repeat no-repeat
	background-size cover
	flex-grow 1

.template-1
	background-image url('../images/bg-template-1.jpg')

.template-2
	padding-bottom 0
	background-image url('../images/bg-template-2.jpg')

	.open-house-form
		position relative
		margin-bottom -100px

	.open-house-form__info
		padding-top 160px
		display flex

.template-3
	background-image url('../images/bg-template-3.jpg')

	.open-house-form__body
		display block

	.open-house-form__info
		margin-top 40px
		padding-top 40px
		display flex
		border-top 1px solid #cecece

.template-4
	background-image url('../images/bg-template-2.jpg')

	.open-house-form__body
		padding-top 0
		flex-direction row-reverse

	.open-house-form
		background transparent

	.open-house-form__form
		margin-right 40px
		padding 0 30px 30px
		background #ffffff
		flex-shrink 0

		.form-custom-input__label
			width 92px

	.open-house-form__info
		padding 30px 30px 0
		background #ffffff

.template-5
	background-image url('../images/bg-template-5.jpg')

	.open-house-form__info
		margin-right -30px
		margin-bottom -30px
		margin-left -30px
		padding-bottom 30px
		background #000000

.form-container
	margin 0 auto
	padding 0 15px
	max-width 1170px

.open-house-form
	margin 0 auto
	padding 0 30px 30px
	width 720px
	background #f5f5f5

	&__header
		padding 30px 0
		display flex
		border-bottom 1px solid #cecece
		align-items center
		justify-content space-between

		&_no-border
			border-bottom none

	&__logo-wrapper
		margin-right auto

	&__logo
		width 185px
		height 35px

	&__text
		margin-bottom 0
		font-family Arial
		font-size 20px
		font-weight 600
		color #000000

	&__body
		padding-top 53px
		display flex
		justify-content space-between

	&__block
		&_mr
			margin-right 40px

		&_grow
			flex-grow 1

	&__form
		margin 0 auto
		width 500px
		flex-grow 1

		.form-custom-input__label
			width auto

	&_bg-white
		background #ffffff

	&_shadow
		box-shadow 0 10px 30px 0 rgba(0, 0, 0, .1)

.no-underline
	text-decoration none
