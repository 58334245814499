.custom-button
	padding 0 17px
	height 30px
	font-family Arial
	font-size 14px
	line-height 30px
	text-align center
	color #000000
	background transparent
	border 1px solid #cccccc
	border-radius 2px

	&:hover
		background-color #e6e6e6
		border-color #adadad

	&_orange
		color #ffffff
		background #ffa701
		border-color #ffa701

		&:hover
			background #ec971f
			border-color #ec971f

	&_mr
		margin-right 10px
