.form-custom-input
	margin-bottom 15px
	display flex
	align-items center
	justify-content space-between
	flex-wrap wrap

	&_arrow
		position relative

		&:after
			position absolute
			top 50px
			left calc(100% + 15px)
			width 50px
			height 2px
			content ''
			background #EDF3FF
			transform translateY(-50%)

		@media screen and (max-width: 992px)
			&:after
				content none

	&__label
		width 92px
		font-family 'Arial'
		font-size 14px
		color #999999

		&_auto
			width auto

		&_grey
			color #666666

		&_white
			color #ffffff

	&__text
		padding-left 10px
		font-family 'Open Sans'
		font-size 14px
		font-weight 400
		color #4A4A4A

	&__input
		padding 0 10px
		min-width 282px
		height 34px
		font-family 'Arial'
		font-size 14px
		line-height 34px
		background #ffffff
		border 1px solid #cccccc

		&_select
			background-image url("data:image/svg+xml,%3Csvg width='10px' height='5px' viewBox='0 0 10 5' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-7.000000, -10.000000)' fill='%23000000'%3E%3Cpolygon points='7 10 12 15 17 10'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
			background-repeat no-repeat
			background-position calc(100% - 7px) center
			background-size 10px 5px
			-webkit-appearance none
			-moz-appearance none
			appearance none

			option
				font-family 'Arial'
				font-size 14px
				color rgba(51, 51, 51, .87)

		&_textarea
			height auto

	&__width
		min-width 282px

	&_align-start
		align-items flex-start
